.ChatFrame {
  background-color: #ffffe7;
  display: flex;
  height: 100%;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 10pt;
}

#ChatIO {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 6px;
}

/* .ChatHistory {
  flex-grow: 1;
} */

.ChatInput {
  margin-top: 6px;
}

.ChatMembers {
  margin-bottom: 6px;
}