.ChatInput {
  display: inline-block;
  height: 38px; /* TODO: This should be 36 */
  padding: 6px;
  background-color: #4a659c;
  box-sizing: border-box;
}

.ChatInput > input[type=text] {
  height: 24px;
  border: 2px solid #6699ff;
  box-sizing: border-box;
  width: calc(100% - 126px);
}

.ChatInput > input[type=submit] {
  margin-left: 12px;
  height: 24px;
  width: 78px;
}

.ChatInput > input[type=button] {
  margin-left: 6px;
  height: 24px;
  width: 26px;
}