.ChatMembers {
  background-color: #efeff7;
  width: 192px;
  display: inline-block;
  display: flex;
  flex-direction: column;
}

.TopBack {
  height: 48px;
  background-color: #a5b2ce;
}

.Self {
  height: 30px;
}

.Nicklist {
  flex-grow: 1;
  min-height: 100px;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid #c0c0c0;
}

.BottomBack {
  height: 36px;
}